import React, {useRef} from "react"
import {graphql, Link, navigate} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ModalLayout from "../components/layout/modalLayout";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation} from 'swiper'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import DefaultHead from "../components/layout/head";

const labels = {
    en:{
        close:'close',
        next:'next',
        previous:'previous',
    },
    fr:{
        close:'fermer',
        next:'suivant',
        previous:'précédent',
    }
}

const PageProject = ({data, pageContext})=>{
    const {lang='fr'} = pageContext
    const {project} = data
    const {frontmatter} = project
    const images = frontmatter.images || []
    const {image_orientation='portrait'} = frontmatter
    const columns = [
        {"id":"title"},
        {"id":"project_type"},
        {"id":"description"},
        {"id":"manufacturer"},
        {"id":"client"},
    ]
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const label = (key) => labels[lang][key]

    function close(){
        navigate(`/${lang}/selection`)
    }

    return <div className={`project-page project-page--${image_orientation||'portrait'}`}>
        <button className="project-page__modal-background" onClick={close} aria-label={label('close')} />
        {/*<Helmet title={WrapMetaTitle(`${title}, ${project_type}, ${manufacturer}, ${client}`)} />*/}
        <div className="project-page__side project-page__side--padding">
            <button className={"site-title"} onClick={close}>Atelier Virginie Morel</button>
            <Link to={`/${lang}/selection`} className="site-ornament" />
        </div>
        <div className="project-page__side project-page__side--images">
            <div className="project-images">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={images.length>1}
                    modules={[Navigation]}
                    navigation={{
                        prevEl:'.slider-nav .button-prev',
                        nextEl:'.slider-nav .button-next'
                    }}
                >
                    {images.map((image,i) =>
                        <SwiperSlide key={i}><GatsbyImage alt={""} image={getImage(image.image)} /></SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
        <div className="project-page__side project-page__side--details">
            <div className="project-page__details">
                <button type={"button"} className={"go-back"} onClick={close}>{label('close')}</button>
                <div className="project-page__infos">
                    <div className="slider-nav" data-image-count={images.length||0}>
                        <button ref={navigationNextRef} type={"button"} className={"next button-next"} aria-label={label('next')} />
                        <button ref={navigationPrevRef} type={"button"} className={"prev button-prev"} aria-label={label('prev')} />
                    </div>
                {columns.map((column,key)=>{
                    const {id}=column
                    const val = frontmatter[id] || ''
                    return (<div key={key} className={`column column-${id}`}>{val}</div>)
                })}
                </div>
            </div>
        </div>
    </div>
}

PageProject.Layout = ModalLayout

export default PageProject

export const query = graphql`
    query ($id: String) {
        project:markdownRemark(id: {eq:$id}) {
            frontmatter {
                client
                id_project
                image_orientation
                images {
                    image {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED, width: 1280, quality: 90)
                        }
                    }
                }
                location
                manufacturer
                project_type
                title
                description
            }
        }
    }
`

export const Head = DefaultHead